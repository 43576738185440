@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://use.typekit.net/xtv6xvg.css");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.hero-slider .slick-slider, 
.hero-slider .slick-list, 
.hero-slider .slick-track,
.hero-slider .slick-slide,
.hero-slider .slick-slide > div {
    height: 100%;
}

.hero-slider .slick-slide > div > div {
    height: 100%;
    width: 100%;
}
body {
  font-family: 'Montserrat', sans-serif;
}

.animated-background {
	background: linear-gradient(-45deg, #bbf7d0, #16a34a, #036635);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.fade-out {
	animation: fadeOut 0.5s forwards;
  }

  .fade-out-tea {
	animation: fadeOutTea 0.5s forwards;
  }

  .fade-out-about {
	animation: fadeOutAbout 0.5s forwards;
  }
  
  /* .fade-in {
	animation: fadeIn 0.5s forwards;
  } */
  
  @keyframes fadeOut {
	0% {
	  opacity: 1;
	}
	50% {
	  opacity: 0;
	}
	100% {
	opacity: 1;
	}
  }
  @keyframes fadeOutTea {
	0% {
	  opacity: 1;
	}
	50% {
	  opacity: 0;
	}
	100% {
	opacity: 1;
	}
  }

  @keyframes fadeOutAbout {
	0% {
	  opacity: 1;
	}
	50% {
	  opacity: 0;
	}
	100% {
	opacity: 1;
	}
}
  /* @keyframes fadeIn {
	0% {
	  opacity: 0;
	}
	100% {
	  opacity: 1;
	}
  } */
